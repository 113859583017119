import { ConnectButton as RainbowButton } from '@rainbow-me/rainbowkit';
import { useMutation } from '@tanstack/react-query';
import classNames from 'classnames';
import { type Token } from '@/shared/assets/tokens';
import Button from '@/shared/components/flip-ui-kit/Button';
import LoadingSpinner from '@/shared/components/LoadingSpinner';
import { useFundingSDK } from '@/shared/hooks';
import { usePolkadot } from '@/shared/hooks/usePolkadot';
import useSteps from '@/shared/hooks/useSteps';
import useToast from '@/shared/hooks/useToast';
import { InformationIcon } from '@/shared/icons/large';
import { unreachable, type TokenAmount } from '@/shared/utils';
import { tryGetEthersError } from '@/shared/utils/functions';

type ConnectButtonProps = {
  token: Token;
  tokenAmount: TokenAmount;
  minimumAmount: TokenAmount;
  className?: string;
  onFunded: (txHash: string) => void;
};

export const ConnectAndFundWalletButton = ({
  token,
  className,
  tokenAmount,
  minimumAmount,
  onFunded,
}: ConnectButtonProps) => {
  const { fundStateChainAccount, approveFlip } = useFundingSDK();
  const [step, nextStep] = useSteps('approve', 'fund', 'success');
  const approveToast = useToast('approve');
  const fundToast = useToast('fund');
  const { selectedAccount } = usePolkadot(true);

  return (
    <RainbowButton.Custom>
      {({ account, chain, openChainModal, openConnectModal, mounted }) => {
        const ready = mounted;

        const connected = ready && account && chain;

        const isLessThanMinimum = tokenAmount.lt(minimumAmount);

        const { mutate: approve, isPending: isApproving } = useMutation({
          mutationFn: async (_: React.MouseEvent) => {
            approveToast.loading('Waiting for token approval...');
            const approved = await approveFlip(tokenAmount.toBigInt());
            if (approved) {
              approveToast.success(
                `${tokenAmount.toPreciseFixedDisplay()} ${token.symbol} approved`,
              );
              nextStep();
            } else {
              approveToast.error('Failed to approve funds :(');
            }
          },
          onError(error) {
            approveToast.error('Failed to approve', {
              description: tryGetEthersError(error, 'Approval'),
            });
          },
        });

        const { mutate: fund, isPending: isFunding } = useMutation({
          mutationFn: async (_: React.MouseEvent) => {
            // TODO: tracking here?

            fundToast.loading('Waiting for confirmation of funding...');
            const txHash = await fundStateChainAccount(
              selectedAccount.idHex,
              tokenAmount.toBigInt(),
            );

            if (txHash) {
              fundToast.success('Your funds are on the way', {
                description:
                  'Please be patient while the funds are credited to your State Chain account.',
              });
              onFunded(txHash);
              nextStep();
            }
          },
          onError(error) {
            fundToast.error('Failed to fund!', {
              description: tryGetEthersError(error, 'Funding'),
            });
          },
        });

        let onClick;
        let content;
        let contentIcon;

        if (isApproving || isFunding) {
          content = 'Waiting for confirmation...';
          contentIcon = <LoadingSpinner />;
        } else if (!connected) {
          onClick = () => {
            // TODO: tracking here?
            openConnectModal();
          };
          content = 'Connect Ethereum wallet';
        } else if (chain.unsupported) {
          onClick = openChainModal;
          content = 'Unsupported network';
        } else {
          switch (step) {
            case 'approve':
              onClick = approve;
              content = 'Approve FLIP';
              contentIcon = <InformationIcon />;
              break;
            case 'fund':
              onClick = fund;
              content = 'Confirm funding';
              contentIcon = undefined;
              break;
            case 'success':
              break;
            default:
              unreachable(step, 'unknown step');
          }
        }

        return (
          <div className="flex flex-col items-center space-y-10">
            <Button
              onClick={onClick}
              className={classNames('flex h-11 justify-center text-16 font-medium', className)}
              icon={contentIcon}
              loading={isApproving || isFunding}
              loadingText="Waiting for confirmation"
              disabled={isApproving || isFunding || isLessThanMinimum}
            >
              {content}
            </Button>
          </div>
        );
      }}
    </RainbowButton.Custom>
  );
};
