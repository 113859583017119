import copyAnimationData from '@/shared/animations/copy.json';
import { copy } from '@/shared/utils';
import Lottie from '@/shared/utils/Lottie';

interface CopyButtonProps {
  textToCopy: string;
}

const CopyButton: React.FC<CopyButtonProps> = ({ textToCopy, ...props }): JSX.Element => {
  const handleClick = (event: React.SyntheticEvent) => {
    event.stopPropagation();
    (event.currentTarget.childNodes[0] as HTMLElement).click();
  };

  const lottie = (
    <Lottie
      as="button"
      className="h-[16px] w-[16px]"
      animationData={copyAnimationData}
      autoplay={false}
      speed={1}
      loop={false}
      onClick={(e) => {
        e.stopPropagation();
        copy(textToCopy);
      }}
      playOnce
      {...props}
    />
  );

  return (
    <div
      className="flex h-[24px] w-[24px] min-w-[24px] cursor-pointer items-center justify-center rounded-md border border-cf-gray-5 bg-cf-gray-4 text-12 text-cf-light-2 transition duration-300 hover:bg-cf-gray-5"
      onClick={handleClick}
    >
      {lottie}
    </div>
  );
};
export default CopyButton;
