import {
  BitcoinLogo,
  PolkadotLogo,
  PolygonLogo,
  EthereumLogo,
  OptimismLogo,
  AvalancheLogo,
  MoonbeamLogo,
  FilecoinLogo,
  BinanceSmartChainLogo,
  FantomLogo,
  CeloLogo,
  ArbitrumLogo,
} from '@/shared/assets/chain-logos';
import {
  BitcoinTransparentLogo,
  EthereumTransparentLogo,
  PolkadotTransparentLogo,
  PolygonTransparentLogo,
} from '@/shared/assets/chain-transparent-logos';
import {
  ethereum,
  bitcoin,
  polygon,
  polkadot,
  arbitrum,
  optimism,
  avalanche,
  moonbeam,
  filecoin,
  bsc,
  fantom,
  celo,
} from './mainnet';
import {
  bitcoinTestnet,
  sepolia,
  polygonMumbai,
  dotTestnet,
  arbitrumSepolia,
  optimismSepolia,
  avalancheFuji,
  moonbaseAlpha,
  filecoinHyperspace,
  bscTestnet,
  fantomTestnet,
  celoAlfajores,
  backspinEthereum,
  backspinArbitrum,
} from './testnet';
import { type ConfigChainId, type SupportedChainId } from './index';

type ChainLogo = (props?: React.SVGProps<SVGSVGElement>) => JSX.Element;

export const chainLogo: Record<SupportedChainId, ChainLogo> = {
  [ethereum.id]: EthereumLogo,
  [bitcoin.id]: BitcoinLogo,
  [polkadot.id]: PolkadotLogo,
  [polygon.id]: PolygonLogo,
  [arbitrum.id]: ArbitrumLogo,
  [sepolia.id]: EthereumLogo,
  [backspinEthereum.id]: EthereumLogo,
  [bitcoinTestnet.id]: BitcoinLogo,
  [dotTestnet.id]: PolkadotLogo,
  [polygonMumbai.id]: PolygonLogo,
  [optimism.id]: OptimismLogo,
  [avalanche.id]: AvalancheLogo,
  [moonbeam.id]: MoonbeamLogo,
  [filecoin.id]: FilecoinLogo,
  [bsc.id]: BinanceSmartChainLogo,
  [fantom.id]: FantomLogo,
  [celo.id]: CeloLogo,
  [arbitrumSepolia.id]: ArbitrumLogo,
  [backspinArbitrum.id]: ArbitrumLogo,
  [optimismSepolia.id]: OptimismLogo,
  [avalancheFuji.id]: AvalancheLogo,
  [moonbaseAlpha.id]: MoonbeamLogo,
  [filecoinHyperspace.id]: FilecoinLogo,
  [bscTestnet.id]: BinanceSmartChainLogo,
  [fantomTestnet.id]: FantomLogo,
  [celoAlfajores.id]: CeloLogo,
} satisfies Record<ConfigChainId, () => JSX.Element>;

export const chainTransparentLogo: Record<SupportedChainId, ChainLogo> = {
  [ethereum.id]: EthereumTransparentLogo,
  [backspinEthereum.id]: EthereumTransparentLogo,
  [bitcoin.id]: BitcoinTransparentLogo,
  [polkadot.id]: PolkadotTransparentLogo,
  [polygon.id]: PolkadotTransparentLogo,
  [arbitrum.id]: ArbitrumLogo,
  [sepolia.id]: EthereumTransparentLogo,
  [bitcoinTestnet.id]: BitcoinTransparentLogo,
  [dotTestnet.id]: PolkadotTransparentLogo,
  [polygonMumbai.id]: PolygonTransparentLogo,
  [optimism.id]: OptimismLogo,
  [avalanche.id]: AvalancheLogo,
  [moonbeam.id]: MoonbeamLogo,
  [filecoin.id]: FilecoinLogo,
  [bsc.id]: BinanceSmartChainLogo,
  [fantom.id]: FantomLogo,
  [celo.id]: CeloLogo,
  [arbitrumSepolia.id]: ArbitrumLogo,
  [backspinArbitrum.id]: ArbitrumLogo,
  [optimismSepolia.id]: OptimismLogo,
  [avalancheFuji.id]: AvalancheLogo,
  [moonbaseAlpha.id]: MoonbeamLogo,
  [filecoinHyperspace.id]: FilecoinLogo,
  [bscTestnet.id]: BinanceSmartChainLogo,
  [fantomTestnet.id]: FantomLogo,
  [celoAlfajores.id]: CeloLogo,
} satisfies Record<ConfigChainId, () => JSX.Element>;
