import { type ChainId } from '@/shared/assets/chains/index';
import { type Token } from '@/shared/assets/tokens/index';
import { TokenLogo } from './TokenLogo';
import { chainLogo } from '../../assets/chains/logo';
import { TokenWithChain, type TokenWithChainSize } from '../TokenWithChain';

export type LogoToken = Pick<Token, 'logo' | 'symbol'> & {
  chain: { id: ChainId };
};

// TODO: Merge this component with TokenWithChain component
const logoSizes = {
  small: 19,
  medium: 24,
  large: 32,
} as const satisfies Record<TokenWithChainSize, number>;

function ChainTokenLogo({
  token,
  size = 'large',
}: {
  token: LogoToken;
  size?: TokenWithChainSize;
}): JSX.Element {
  const logoSize = logoSizes[size];
  return (
    <TokenWithChain
      chainLogo={chainLogo[token.chain.id]}
      tokenLogo={<TokenLogo token={token} width={logoSize} height={logoSize} />}
      size={size}
    />
  );
}

export default ChainTokenLogo;
