import { useCallback, useMemo } from 'react';
import { toast as rawToast } from 'sonner';

type ToastArgs = Parameters<typeof rawToast.success>;
type ToasterFn = (...params: ToastArgs) => void;
type ToastType = 'success' | 'error' | 'loading';
type Toast = {
  [key in ToastType]: ToasterFn;
};

type DismissableToast = Toast & {
  dismiss: () => void;
};

export default function useToast(name: string): DismissableToast {
  const invoke = useCallback(
    (type: 'success' | 'error' | 'loading', [message, data]: ToastArgs) => {
      rawToast[type](message, {
        duration: type === 'loading' ? Infinity : 5000,
        description: undefined,
        ...data,
        id: name,
      });
    },
    [],
  );

  const toast: DismissableToast = useMemo(
    () => ({
      loading: (...params) => invoke('loading', params),
      success: (...params) => invoke('success', params),
      error: (...params) => invoke('error', params),
      dismiss: () => rawToast.dismiss(name),
    }),
    [],
  );

  return toast;
}
