export const ChartLegend = ({ items }: { items: { label: string; color: string }[] }) => (
  <div className="flex flex-wrap gap-x-6 text-12 text-cf-light-2">
    {items.map(({ label, color }) => (
      <div className="flex flex-row items-center gap-x-1.5" key={`${label}-${color}`}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
        >
          <rect x="2" y="2" width="8" height="8" rx="1" fill={color} />
        </svg>
        {label}
      </div>
    ))}
  </div>
);
