import { useCallback, useState } from 'react';
import BigNumber from 'bignumber.js';
import classNames from 'classnames';
import { type Token } from '@/shared/assets/tokens';
import { ChainTokenLogo } from '@/shared/components';
import { TokenAmount } from '@/shared/utils';
import { decimalRegex } from '@/shared/utils/regex';

export interface TokenInputFieldProps {
  token: Token;
  inputValue: string;
  setInputValue: (value: string) => void;
  balance?: TokenAmount;
  maxAmount?: TokenAmount;
  subtext?: string;
}

export default function TokenInputField({
  token,
  inputValue,
  setInputValue,
  balance,
  maxAmount,
  subtext,
}: TokenInputFieldProps): JSX.Element {
  const [exceedMaxError, setExceedMaxError] = useState(false);

  const onChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setExceedMaxError(false);
      const val = event.target.value;
      if (val === '.') {
        setInputValue('0.');
        return;
      }
      if (!decimalRegex(token?.decimals).test(val)) return;
      const amount = TokenAmount.fromWholeUnits(val, token?.decimals ?? 18);
      const max = maxAmount ?? balance;
      if (max && amount.gt(max)) {
        setExceedMaxError(true);
        return;
      }
      setInputValue(val);
    },
    [balance],
  );

  return (
    <div className="group relative flex h-[4.5rem] w-full text-cf-light-4">
      <div className="relative">
        {(maxAmount || balance)?.gt(0) && (
          <button
            className="absolute right-3 top-1/2 -translate-y-1/2 rounded-md border border-cf-gray-4 bg-cf-gray-2 px-1.5 py-0.5 font-aeonikMono text-10 uppercase opacity-0 transition hover:bg-cf-gray-3 hover:text-cf-white group-focus-within:opacity-100 group-hover:opacity-100"
            onClick={() => {
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              setInputValue((maxAmount || balance)!.toFixed());
            }}
            type="button"
          >
            Max
          </button>
        )}
        <input
          placeholder="0"
          type="text"
          inputMode="numeric"
          className={classNames(
            'peer h-full max-w-[260px] rounded-l-md border border-cf-gray-4 bg-cf-gray-2 pl-3 font-aeonikMono text-24 text-white outline-none transition placeholder:text-cf-light-2 hover:bg-cf-gray-3 disabled:cursor-not-allowed disabled:hover:bg-cf-gray-2',
            exceedMaxError && 'border-cf-red-1',
            subtext && 'pb-3',
          )}
          value={inputValue}
          onChange={onChange}
          onBlur={() => {
            if (inputValue === '') return;
            const amount = new BigNumber(inputValue);
            setInputValue(amount.eq(0) ? '' : amount.toFixed());
          }}
        />
        {subtext && (
          <span className="absolute bottom-0.5 left-3 font-aeonikMono text-12 text-cf-light-3/50">
            {subtext}
          </span>
        )}
      </div>
      <div className="flex w-full min-w-[169px] items-center justify-center gap-3 rounded-r-md border border-l-0 border-cf-gray-4 bg-cf-gray-2 px-5 py-4">
        <ChainTokenLogo token={token} />
        <div className="ml-1 flex flex-col items-start">
          <div className="font-aeonikBold uppercase text-white">{token.symbol}</div>
          <span className="w-full truncate text-left text-12 text-cf-light-1">
            on {token.chain.name}
          </span>
        </div>
      </div>
      {balance && (
        <div className="absolute right-0 top-full flex flex-row pt-1.5 font-aeonikMono text-10 text-cf-light-1">
          Balance:&nbsp;
          <span className="text-cf-white">
            {balance.toPreciseFormattedString()} {token.symbol}
          </span>
        </div>
      )}
      {exceedMaxError && (
        <div className="absolute left-0 top-full pt-1.5 text-12 text-cf-red-1">
          Amount exceeds {maxAmount ? 'max' : 'balance'}
        </div>
      )}
    </div>
  );
}
