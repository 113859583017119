import { useCallback, useState } from 'react';

export default function useSteps<const T extends string>(...steps: T[]) {
  const [step, setStep] = useState<T>(steps[0]);

  const next = useCallback(() => {
    const currentIndex = steps.indexOf(step);
    const nextIndex = (currentIndex + 1) % steps.length;
    setStep(steps[nextIndex]);
  }, []);

  return [step, next, setStep] as const;
}
