import { useState } from 'react';
import classNames from 'classnames';

export const TabPanel = ({
  nodes,
  shouldOverflow = true,
}: {
  nodes: { name: string; content: JSX.Element; tabLabel?: React.ReactNode }[];
  shouldOverflow?: boolean;
}) => {
  const [active, setActive] = useState(nodes[0].name);

  return (
    <div className="flex w-full flex-col">
      <div className="flex w-full overflow-x-auto rounded-t-md border border-b-0 border-cf-gray-3-5 bg-cf-gray-2">
        {nodes.map((node) => (
          <button
            type="button"
            key={node.name}
            className={classNames(
              `whitespace-nowrap border-r border-cf-gray-3-5 px-[20px] py-[12px] font-aeonikMedium text-14 text-cf-light-2 transition duration-100 ease-in first-of-type:rounded-tl-md hover:bg-cf-gray-3 hover:text-cf-light-3`,
              active === node.name
                ? 'bg-cf-gray-4 text-cf-light-3 hover:bg-cf-gray-4'
                : 'bg-cf-gray-2',
            )}
            onClick={() => active !== node.name && setActive(node.name)}
          >
            {node.tabLabel || node.name}
          </button>
        ))}
      </div>
      <div
        className={classNames(
          'cf-is-panel group flex w-full flex-col',
          shouldOverflow && 'overflow-x-auto',
        )}
      >
        {nodes.find((node) => node.name === active)?.content}
      </div>
    </div>
  );
};
